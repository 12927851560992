import statisticsApi from "@/api/statistics";

const state = {
  statisticsCounters: {},
  statisticsCustomers: {},
  statisticsRequests: {},
  isLoading: false,
  errors: [],
};

export const mutationTypes = {
  getStatisticsCountersStart: "[statistics] getStatisticsCountersStart",
  getStatisticsCountersFailed: "[statistics] getStatisticsCountersFailed",
  getStatisticsCountersSuccess: "[statistics] getStatisticsCountersSuccess",

  getStatisticsCustomersStart: "[statistics] getStatisticsCustomersStart",
  getStatisticsCustomersFailed: "[statistics] getStatisticsCustomersFailed",
  getStatisticsCustomersSuccess: "[statistics] getStatisticsCustomersSuccess",

  getStatisticsRequestsStart: "[statistics] getStatisticsRequestsStart",
  getStatisticsRequestsFailed: "[statistics] getStatisticsRequestsFailed",
  getStatisticsRequestsSuccess: "[statistics] getStatisticsRequestsSuccess",
};

const mutations = {
  [mutationTypes.getStatisticsCountersStart](state) {
    state.isLoading = true;
    state.errors = [];
  },
  [mutationTypes.getStatisticsCountersSuccess](state, payload) {
    state.isLoading = false;
    state.statisticsCounters = payload;
    state.errors = [];
  },
  [mutationTypes.getStatisticsCountersFailed](state, payload) {
    state.isLoading = false;
    state.errors = payload;
  },

  [mutationTypes.getStatisticsCustomersStart](state) {
    state.isLoading = true;
    state.errors = [];
  },
  [mutationTypes.getStatisticsCustomersSuccess](state, payload) {
    state.isLoading = false;
    state.statisticsCustomers = payload;
    state.errors = [];
  },
  [mutationTypes.getStatisticsCustomersFailed](state, payload) {
    state.isLoading = false;
    state.errors = payload;
  },

  [mutationTypes.getStatisticsRequestsStart](state) {
    state.isLoading = true;
    state.errors = [];
  },
  [mutationTypes.getStatisticsRequestsSuccess](state, payload) {
    state.isLoading = false;
    state.statisticsRequests = payload;
    state.errors = [];
  },
  [mutationTypes.getStatisticsRequestsFailed](state, payload) {
    state.isLoading = false;
    state.errors = payload;
  },
};

export const actionTypes = {
  getStatisticsCounters: "[statistics] getStatisticsCounters",
  getStatisticsCustomers: "[statistics] getStatisticsCustomers",
  getStatisticsRequests: "[statistics] getStatisticsRequests",
};

const actions = {
  async [actionTypes.getStatisticsCounters](context) {
    try {
      context.commit(mutationTypes.getStatisticsCountersStart);

      const res = await statisticsApi.getStatisticsCounters();
      context.commit(mutationTypes.getStatisticsCountersSuccess, res.data);

      return true;
    } catch (e) {
      context.commit(mutationTypes.getStatisticsCountersFailed, e.response.data.error);

      return false;
    }
  },

  async [actionTypes.getStatisticsCustomers](context, payload) {
    try {
      context.commit(mutationTypes.getStatisticsCustomersStart);

      const res = await statisticsApi.getStatisticsCustomers(payload);
      context.commit(mutationTypes.getStatisticsCustomersSuccess, res.data);

      return true;
    } catch (e) {
      context.commit(mutationTypes.getStatisticsCustomersFailed, e.response.data.error);

      return false;
    }
  },

  async [actionTypes.getStatisticsRequests](context, payload) {
    try {
      context.commit(mutationTypes.getStatisticsRequestsStart);
      const res = await statisticsApi.getStatisticsRequests(payload);
      context.commit(mutationTypes.getStatisticsRequestsSuccess, res.data);
      return true;
    } catch (e) {
      context.commit(mutationTypes.ugetStatisticsRequestsFailed, e.response.data.error);
      return false;
    }
  },
};

const getters = {}

export default {
  state,
  mutations,
  actions,
  getters
};
