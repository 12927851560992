import Vue from "vue";
import VueRouter from "vue-router";
import axios from "@/plugins/axios";
import store from "@/store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/dashboard",
    name: "Home",
    meta: {
      layout: "Main",
      auth: true,
    },
    component: () => import("@/views/Home.vue"),
  },
  {
    path: "/profile",
    name: "Profile",
    meta: {
      layout: "Main",
      auth: true,
    },
    component: () => import("@/views/Profile.vue"),
  },
  {
    path: "/sign-in",
    name: "Sign in",
    meta: {
      layout: "Empty",
      auth: false,
    },
    component: () => import("@/views/LoginView.vue"),
  },
  {
    path: "/account-suspended",
    name: "Account suspended",
    meta: {
      layout: "Empty",
    },
    component: () => import("@/views/Suspended.vue"),
  },
  {
    path: "/sign-in-tries-limit",
    name: "Sign-in attempt limit",
    meta: {
      layout: "Empty",
    },
    component: () => import("@/views/Fail.vue"),
  },
  {
    path: "/sign-in/otp",
    name: "OTP code",
    meta: {
      layout: "Empty",
    },
    component: () => import("@/views/OtpView.vue"),
  },
  {
    path: "/sign-in/otp-recovery",
    name: "OTP recovery code",
    meta: {
      layout: "Empty",
    },
    component: () => import("@/views/OtpRecovery.vue"),
  },
  {
    path: "/forgot-password",
    name: "Forgot password",
    meta: {
      layout: "Empty",
    },
    component: () => import("@/views/NewPass.vue"),
  },
  {
    path: "/reset-password",
    name: "Password update",
    meta: {
      layout: "Empty",
    },
    component: () => import("@/views/CreatePass.vue"),
  },
  {
    path: "/email-not-confirmed",
    name: "Email not confirmation",
    meta: {
      layout: "Empty",
    },
    component: () => import("@/views/NotConfirm.vue"),
  },
  {
    path: "/email-confirmation",
    name: "Email confirmation",
    meta: {
      layout: "Empty",
      auth: false,
    },
    component: () => import("@/views/Confirm.vue"),
  },
  {
    path: "/staff",
    name: "Staff Management",
    meta: {
      layout: "Main",
      auth: true,
    },
    component: () => import("@/views/Staff.vue"),
  },
  {
    path: "/staff/:id",
    name: "Edit staff account",
    meta: {
      layout: "Main",
      auth: true,
    },
    component: () => import("@/views/StaffMember.vue"),
  },
  {
    path: "/organizations",
    name: "Organizations",
    meta: {
      layout: "Main",
      auth: true,
    },
    component: () => import("@/views/Organizations.vue"),
  },
  {
    path: "/organizations/:id",
    name: "Organization",
    meta: {
      layout: "Main",
      auth: true,
    },
    component: () => import("@/views/Organization.vue"),
  },
  {
    path: "/organization-details",
    name: "Bank",
    meta: {
      layout: "Main",
      auth: true,
    },
    component: () => import("@/views/Bank.vue"),
  },
  {
    path: "/real-estate-requests",
    name: "Real estates",
    meta: {
      layout: "Main",
      auth: true,
    },
    component: () => import("@/views/RealEstates.vue"),
  },
  {
    path: "/real-estate-requests/:id",
    name: "Real estate",
    meta: {
      layout: "Main",
      auth: true,
    },
    component: () => import("@/views/RealEstate.vue"),
  },

  {
    path: "/requests",
    name: "Requests",
    meta: {
      layout: "Main",
      auth: true,
    },
    component: () => import("@/views/Requests.vue"),
  },
  {
    path: "/requests/new",
    name: "Requests new",
    meta: {
      layout: "Main",
      auth: true,
    },
    component: () => import("@/views/RequestsNew.vue"),
  },
  {
    path: "/requests/:id",
    name: "Request",
    meta: {
      layout: "Main",
      auth: true,
    },
    component: () => import("@/views/Request.vue"),
  },
  {
    path: "/requests/:id/edit",
    name: "Request",
    meta: {
      layout: "Main",
      auth: true,
    },
    component: () => import("@/views/RequestsEdit.vue"),
  },
  {
    path: "/products",
    name: "Products",
    redirect: "/", // delete if you want uncomment
    meta: {
      layout: "Main",
      auth: true,
    },
    // component: () => import("@/views/Products.vue"),
  },
  {
    path: "/products/:id",
    name: "Product",
    meta: {
      layout: "Main",
      auth: true,
    },
    component: () => import("@/views/Product.vue"),
  },
  {
    path: "/apr-settings",
    name: "APR Settings",
    meta: {
      layout: "Main",
      auth: true,
    },
    component: () => import("@/views/AprList.vue"),
  },
  {
    path: "/loan-offers",
    name: "Loan offers",
    meta: {
      layout: "Main",
      auth: true,
    },
    component: () => import("@/views/AprList.vue"),
  },
  {
    path: "/customers",
    name: "Customers",
    meta: {
      layout: "Main",
      auth: true,
    },
    component: () => import("@/views/Customers.vue"),
  },
  {
    path: "/customers/:id",
    name: "Customer",
    meta: {
      layout: "Main",
      auth: true,
    },
    component: () => import("@/views/Customer.vue"),
  },
  {
    path: "/agencies",
    name: "Agencies",
    meta: {
      layout: "Main",
      auth: true,
    },
    component: () => import("@/views/Agencies.vue"),
  },
  {
    path: "/agencies/:id",
    name: "Agency",
    meta: {
      layout: "Main",
      auth: true,
    },
    component: () => import("@/views/Agency.vue"),
  },
  {
    path: "/agents",
    name: "Agents",
    meta: {
      layout: "Main",
      auth: true,
    },
    component: () => import("@/views/Agents.vue"),
  },
  {
    path: "/agents/:id",
    name: "Agent",
    meta: {
      layout: "Main",
      auth: true,
    },
    component: () => import("@/views/Agent.vue"),
  },
  {
    path: "/properties",
    name: "Properties",
    meta: {
      layout: "Main",
      auth: true,
    },
    component: () => import("@/views/Properties.vue"),
  },
  {
    path: "/properties/:id",
    name: "Property",
    meta: {
      layout: "Main",
      auth: true,
    },
    component: () => import("@/views/Property.vue"),
  },
  {
    path: "/projects",
    name: "Projects",
    meta: {
      layout: "Main",
      auth: true,
    },
    component: () => import("@/views/ProjectsList.vue"),
  },
  {
    path: "/projects/:id",
    name: "Project",
    meta: {
      layout: "Main",
      auth: true,
    },
    component: () => import("@/views/Project.vue"),
  },
  {
    path: "/tickets",
    name: "Tickets",
    meta: {
      layout: "Main",
      auth: true,
    },
    component: () => import("@/views/Tickets.vue"),
  },
  {
    path: "/tickets/:id",
    name: "Ticket",
    meta: {
      layout: "Main",
      auth: true,
    },
    component: () => import("@/views/Ticket.vue"),
  },
  {
    path: "/settings",
    name: "Settings",
    meta: {
      layout: "Main",
      auth: true,
    },
    component: () => import("@/views/Settings.vue"),
  },
  {
    path: "*",
    redirect: { name: "Home" },
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

router.beforeEach(async (to, from, next) => {
  if (to.meta.layout === 'Main') {
    const isAuthenticated = store.state.profile.profile?.id

    if (isAuthenticated) {
      return next();
    }

    const res = await axios.get('/platform/api/v1/check-authenticated').catch(() => {})
    if (res && res.data.authenticated) {
      // store.commit('getProfileSuccess', res.data.profile)
      // store.commit('getAgencySuccess', res.data.agency)
      return next();
    }

    next('/sign-in');
  } else {
    next();
  }
});

export default router;