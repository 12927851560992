import axios from "@/plugins/axios";

const login = (data) => {
  return axios.post("/platform/api/v1/sign-in/email", data);
};

const logout = () => {
  return axios.post("/platform/api/v1/sign-out");
};

const otp = (data) => {
  return axios.post("/platform/api/v1/sign-in/otp", data);
};

const otpRecovery = (data) => {
  return axios.post("/platform/api/v1/sign-in/otp-recovery", data);
};

const sendNewPassword = (data) => {
  return axios.post("/platform/api/v1/request-password-reset", data);
};

const updatePassword = (data) => {
  return axios.post("/platform/api/v1/password-reset", data);
};

const confirmEmail = (data) => {
  return axios.post("/platform/api/v1/confirm-email", data);
};

export default {
  login,
  logout,
  otp,
  otpRecovery,
  sendNewPassword,
  updatePassword,
  confirmEmail,
};
