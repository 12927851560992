import authApi from "@/api/auth";
import { setItem } from "@/helpers/persistanceStorage";
import { i18n } from "@/plugins/i18n";
import {app} from "@/main";

const state = {
  isLoggedIn: false,
  isSubmitting: false,
  errors: [],
  role: null,
};

export const mutationTypes = {
  authStart: "[auth] authStart",
  authFailed: "[auth] authFailed",
  authSuccess: "[auth] authSuccess",
  setLoggedIn: "[auth] setLoggedIn",
  setRole: "[auth] setRole",
};

const mutations = {
  [mutationTypes.setRole](state, payload) {
    state.role = payload;
  },
  [mutationTypes.authStart](state) {
    state.isSubmitting = true;
    state.errors = [];
  },
  [mutationTypes.authSuccess](state) {
    state.isSubmitting = false;
    state.errors = [];
  },
  [mutationTypes.authFailed](state, payload) {
    state.isSubmitting = false;
    state.errors = payload;
  },
  [mutationTypes.setLoggedIn](state, payload) {
    state.isLoggedIn = payload;
  },
};

export const actionTypes = {
  login: "[auth] login",
  otp: "[auth] otp",
  otpRecovery: "[auth] otpRecovery",
  sendNewPassword: "[auth] sendNewPassword",
  updatePassword: "[auth] updatePassword",
  confirmEmail: "[auth] confirmEmail",
  logout: "[auth] logout",
  refresh: "[auth] refresh",
};

const actions = {
  async [actionTypes.login](context, params) {
    try {
      context.commit(mutationTypes.authStart);

      const { data } = await authApi.login(params);

      if (data.auth_token) {
        setItem("jwt_token", data.auth_token);
      } else if (data.access_token) {
        setItem("jwt_token", data.access_token);
        context.commit(mutationTypes.setLoggedIn, true);
      }
      context.commit(mutationTypes.authSuccess);

      return data;
    } catch (e) {
      context.commit(mutationTypes.authFailed, e.response.data.error);

      return false;
    }
  },

  async [actionTypes.otp](context, params) {
    try {
      context.commit(mutationTypes.authStart);

      const response = await authApi.otp(params);
      setItem("jwt_token", response.data.access_token);
      context.commit(mutationTypes.setLoggedIn, true);
      context.commit(mutationTypes.authSuccess);

      return true;
    } catch (e) {
      context.commit(mutationTypes.authFailed, e.response.data.error);

      return false;
    }
  },

  async [actionTypes.otpRecovery](context, params) {
    try {
      context.commit(mutationTypes.authStart);

      const response = await authApi.otpRecovery(params);
      setItem("jwt_token", response.data.access_token);
      context.commit(mutationTypes.setLoggedIn, true);
      context.commit(mutationTypes.authSuccess);

      return true;
    } catch (e) {
      context.commit(mutationTypes.authFailed, e.response.data.error);

      return false;
    }
  },

  async [actionTypes.sendNewPassword](context, payload) {
    try {
      context.commit(mutationTypes.authStart);

      await authApi.sendNewPassword(payload);
      context.commit(mutationTypes.authSuccess);

      return true;
    } catch (e) {
      context.commit(mutationTypes.authFailed, e.response.data.error);

      return false;
    }
  },

  async [actionTypes.updatePassword](context, payload) {
    try {
      context.commit(mutationTypes.authStart);

      const { data } = await authApi.updatePassword(payload);
      if (data.auth_token) {
        setItem("jwt_token", data.auth_token);
      } else if (data.access_token) {
        context.commit(mutationTypes.setLoggedIn, true);
        setItem("jwt_token", data.access_token);
      }

      context.commit(mutationTypes.authSuccess);

      return data;
    } catch (e) {
      context.commit(mutationTypes.authFailed, e.response.data.error);

      return false;
    }
  },

  async [actionTypes.confirmEmail](context, payload) {
    try {
      context.commit(mutationTypes.authStart);

      const response = await authApi.confirmEmail(payload);
      setItem("jwt_token", response.data.access_token);
      context.commit(mutationTypes.setLoggedIn, true);
      context.commit(mutationTypes.authSuccess);

      return true;
    } catch (e) {
      context.commit(mutationTypes.authFailed, e.response.data.error);

      return false;
    }
  },

  async [actionTypes.logout](context) {
    try {
      context.commit(mutationTypes.authStart);
      await authApi.logout();
      sessionStorage.removeItem("jwt_token");
      context.commit(mutationTypes.setLoggedIn, false);
      context.commit(mutationTypes.authSuccess);
      app.$router.push("/sign-in").catch(() => {});
      return true;
    } catch (e) {
      context.commit(mutationTypes.authFailed, e.response.data.error);

      return false;
    }
  },
};

export const getterTypes = {
  emailErrors: "[auth] emailErrors",
  otpErrors: "[auth] otpErrors",
  otpRecoveryErrors: "[auth] otpRecoveryErrors",
  passwordErrors: "[auth] passwordErrors",
  emailForPasswordErrors: "[auth] EmailForPasswordErrors",
  newPasswordErrors: "[auth] newPasswordErrors",
};

const getters = {
  [getterTypes.emailErrors]({ errors }) {
    const result = [];

    errors.find((item) => item == "email__required") &&
      result.push(i18n.t("errors.emailRequired"));
    errors.find((item) => item == "email__invalid") &&
      result.push(i18n.t("errors.emailInvalid"));
    errors.find((item) => item == "credential__invalid") && result.push("");
    return result;
  },
  [getterTypes.passwordErrors]({ errors }) {
    const result = [];

    errors.find((item) => item == "password__required") &&
      result.push(i18n.t("errors.passwordRequired"));
    errors.find((item) => item == "credential__invalid") && result.push("");

    return result;
  },
  [getterTypes.otpErrors](state) {
    const result = [];

    state.errors.find((item) => item == "code__required") &&
      result.push(i18n.t("errors.otpRequired"));
    state.errors.find((item) => item == "code__invalid") &&
      result.push(i18n.t("errors.otpInvalid"));
    state.errors.find((item) => item == "otp__invalid") &&
      result.push(i18n.t("errors.otpInvalid"));

    return result;
  },
  [getterTypes.otpRecoveryErrors](state) {
    const result = [];

    state.errors.find((item) => item == "recovery_code__required") &&
      result.push(i18n.t("errors.otpRecoveryRequired"));
    state.errors.find((item) => item == "recovery_code__invalid") &&
      result.push(i18n.t("errors.otpRecoveryInvalid"));

    return result;
  },

  [getterTypes.emailForPasswordErrors](state) {
    const result = [];

    state.errors.find((item) => item == "email__required") &&
      result.push(i18n.t("errors.emailRequired"));
    state.errors.find((item) => item == "email__invalid") &&
      result.push(i18n.t("errors.emailInvalid"));
    state.errors.find((item) => item == "staff__not_found") &&
      result.push(i18n.t("errors.staffNotFound"));
    state.errors.find((item) => item == "action__limit_exceeded") &&
      result.push(i18n.t("errors.resetPasswordTooOften"));

    return result;
  },

  [getterTypes.newPasswordErrors](state) {
    const result = [];

    state.errors.find((item) => item == "password__required") &&
      result.push(i18n.t("errors.passwordRequired"));
    state.errors.find((item) => item == "password__invalid") &&
      result.push(i18n.t("errors.passwordInvalid"));
    return result;
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
