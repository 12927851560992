import axios from "axios";
import { getItem, setItem } from "@/helpers/persistanceStorage";
import { app } from "@/main.js";
import { mutationTypes } from "@/store/modules/auth";
import {i18n} from "@/plugins/i18n";

const _axios = axios.create({});

_axios.interceptors.request.use((config) => {
  const token = getItem("jwt_token");
  const authorizationToken = token ? `Bearer ${token}` : "";

  config.headers.Authorization = authorizationToken;
  config.headers['Accept-Language'] = i18n.locale;

  return config;
});

_axios.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const originalConfig = err.config;

    if (err.response) {
      if (err.response.data?.error?.includes('action__limit_exceeded')) {
        app.$notify({
          horizontalAlign: "center",
          verticalAlign: "top",
          message: i18n.t('errors.action__limit_exceeded'),
          type: 'error'
        })
        return Promise.reject(err);
      }


      if (err.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;
        app.$router.push("/sign-in").catch(() => {});
        sessionStorage.removeItem("jwt_token");
        app.$store.commit(mutationTypes.setLoggedIn, false);
        return Promise.reject(err);
      }
    }

    return Promise.reject(err);
  }
);
export default _axios;
