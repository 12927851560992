<template>
  <div>
    <Header />
    <div class="main-layout back-blue">
      <notifications></notifications>
      <Menu/>
      <div class="content">
        <router-view class="width100"/>
      </div>
    </div>
  </div>
</template>
<script>
import Header from "@/components/shared/Header";
import Menu from "@/components/shared/Menu";
import { actionTypes, mutationTypes } from "@/store/modules/auth";
import { mutationTypes as ticketMutationTypes } from "@/store/modules/ticket";
import {
  actionTypes as notificationActionTypes,
  mutationTypes as notificationMutationTypes,
} from "@/store/modules/notification";
import { getItem } from "@/helpers/persistanceStorage";
import { subscribeToNotifications } from "@/api/notification";
import jwt_decode from "jwt-decode";
import ToastNotification from "@/components/shared/ToastNotification";

export default {
  // eslint-disable-next-line vue/no-unused-components
  components: { Header, Menu },
  created() {
    document.getElementById("html").style.overflowY = "hidden";
  },
  async mounted() {
    // await this.$store.dispatch(actionTypes.refresh);
    this.updateRole();

    subscribeToNotifications(getItem("jwt_token"), [
      this.handleNotification,
      this.handleMessageUpdate,
      this.handleConversationUpdate,
    ]);
  },

  methods: {
    showToast(message, requestId, messageId) {
      const content = {
        component: ToastNotification,
        props: {
          message,
        },
      };

      this.$toast(content, {
        position: "top-right",
        onClick: () => {
          this.openRequest(requestId, messageId);
        },
        icon: false,
        hideProgressBar: true,
        timeout: 1000000,
      });
    },

    showToastAgency(message, number, messageId) {
      const content = {
        component: ToastNotification,
        props: {
          message,
        },
      };

      this.$toast(content, {
        position: "top-right",
        onClick: () => {
          this.openAgencies(number, messageId);
        },
        icon: false,
        hideProgressBar: true,
        timeout: 1000000,
      });
    },
    handleNotification(notification) {
      const { type, id, data } = notification.payload;
      const requestId = data.financing_request.number;
      const messageId = id;

      if (type === "financing_request_submitted_v1") {
        this.showToast(this.$t(type, { id: requestId }), requestId, messageId);
        this.updateNotifications();
      }

      if (type === "agency_domain_updated_v1") {
        this.showToastAgency(this.$t(type, { title: data.agency?.title }), data.agency?.number, messageId);
        this.updateNotifications();
      }

    },
    handleConversationUpdate(response) {
      this.$store.commit(
        ticketMutationTypes.addUpdatedConversation,
        response.payload
      );
    },
    handleMessageUpdate(response) {
      const { conversation } = response.payload;
      if (conversation === this.routeId) {
        this.$store.commit(ticketMutationTypes.addNewMessage, response.payload);
      }
    },

    async openRequest(requestId, messageId) {
      this.$router.push(`/requests/${requestId}`);
      await this.$store.dispatch(
        notificationActionTypes.readMessage,
        messageId
      );

      this.updateNotifications();
    },

    async openAgencies(number, messageId) {
      this.$router.push(`/agencies/${number}`);
      await this.$store.dispatch(
          notificationActionTypes.readMessage,
          messageId
      );

      this.updateNotifications();
    },

    updateNotifications() {
      this.$store.commit(notificationMutationTypes.changeNotificationsId);
    },

    updateRole() {
      const token = getItem("jwt_token");
      this.$store.commit(mutationTypes.setRole, jwt_decode(token).extra.role);
    },
  },
  computed: {
    routeId() {
      return this.$route.params.id;
    },
  },
};
</script>
<style lang="scss">
.main-layout {
  overflow-y: hidden;
  display: flex;
  max-height: calc(100vh - 64px);
  .content {
    width: 100%;
    max-height: calc(100vh - 64px);
    overflow-y: scroll;
  }
}
</style>
